import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private api: ApiService
  ) { }

  public gamesJSON(
    page: number,
    perPage: number,
    providers: Array<any>,
    gameTypes: Array<any>,
    tags: Array<any>,
    searchValue: string,
    sortBy: string,
    sortType: "asc" | "desc"

  ) {
    let dataJSON: Record<string, any> = {
      page: page,
      perPage: perPage,
      providers: providers,
      gameTypes: gameTypes,
      tags: tags,
      searchValue: searchValue,
      sortBy: sortBy,
      sortType: sortType
    };
    return this.api.requestPOST('gamesjson', dataJSON);
  }

  public gamesEvent() {
    let dataJSON: Record<string, any> = {}
    return this.api.requestPOST('gamesEvent', dataJSON);
  }
  public headerGameJSON() {
    let dataJSON: Record<string, any> = {}
    return this.api.requestPOST('headerGame', dataJSON);
  }
  public jackpotValueJSON() {
    let dataJSON: Record<string, any> = {}
    return this.api.requestPOST('jackpotValue', dataJSON);
  }
  public crashGamesJSON() {
    let dataJSON: Record<string, any> = {}
    return this.api.requestPOST('crashGames', dataJSON);
  }
  public gameshowGamesJSON() {
    let dataJSON: Record<string, any> = {}
    return this.api.requestPOST('gameshowGames', dataJSON);
  }
  public getCaptcha() {
    let dataJSON: Record<string, any> = {}
    return this.api.requestTEXT('captcha', dataJSON);
  }
  public checkCaptcha(
    captcha: string,
  ): Observable<any> {
    let dataJSON: Record<string, any> = {
      captcha: captcha,
    };
    return this.api.requestPOST('checkcaptcha', dataJSON);
  }

  public loginTest(
    username: string,
    password: string,
  ) {
    let dataJSON: Record<string, any> = {
      username: username,
      password: password
    };
    return this.api.requestData('login', dataJSON);
  }

  public login(
    username: string,
    password: string
  ): Observable<any> {
    let dataJSON: Record<string, any> = {
      username: username,
      password: password
    };
    return this.api.requestPOST('login', dataJSON);
  }

  public logout(
    session: string,
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
    };
    return this.api.requestPOST('logout', dataJSON);
  }

  public getPromoList(
    session: string,
  ): Observable<any> {
    let dataJSON: Record<string, any> = {
      session: session,
    };
    return this.api.requestPOST('promolist', dataJSON);
  }

  public getSession(
    session: string
  ): Observable<any> {
    let dataJSON: Record<string, any> = {
      session: session,
    };
    return this.api.requestPOST('session', dataJSON);
  }
  
  public getSessionUser(
    session: string
  ): Observable<any> {
    let dataJSON: Record<string, any> = {
      session: session,
    };
    return this.api.requestPOST('sessionUser', dataJSON);
  }

  public editProfile(
    session: string,
    id: string,
    first_name: string,
    last_name: string,
    nickname: string,
    email: string,
    mobile_phone: string,
    mobile_phone_country: string
  ): Observable<unknown> {
    let dataJSON: Record<string, any> = {
      id: id,
      session: session,
      first_name: first_name,
      last_name: last_name,
      nickname: nickname,
      email: email,
      mobile_phone: mobile_phone,
      mobile_phone_country: mobile_phone_country
    }

    return this.api.requestPOST('editProfile', dataJSON);
  }

  public editPassword(
    session: string,
    id: string,
    password: string,
    current_password: string
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
      password: password,
      current_password: current_password,
    };

    return this.api.requestPOST('editPassword', dataJSON);
  }

  public editPIN(
    session: string,
    id: string,
    pin: string,
    old_pin: string
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
      pin: pin,
      old_pin: old_pin,
    };

    return this.api.requestPOST('editPIN', dataJSON);
  }

  public guestLogin() {
    let dataJSON: Record<string, any> = {};
    return this.api.requestPOST('guestLogin', dataJSON);
  }

  public facebookPixel(
    id: string,
    token: string,
    event: string,
    fbc: string,
    fbp: string,
    username: string,
    phone: string,
    value: string,
    transaction_id: string,
    bank_name: string,
    bank_number: string,
    account_name: string,
    referral: string,
  ) {
    let dataJSON: Record<string, any> = {
      id : id,
      token : token,
      event : event,
      fbc : fbc,
      fbp : fbp,
      username : username,
      phone : phone,
      value : value,
      transaction_id : transaction_id,
      bank_name : bank_name,
      bank_number : bank_number,
      account_name : account_name,
      referral : referral
    };
    return this.api.requestPOST('facebookPixel', dataJSON);
  }

  public guestLogout(session: string) {
    let dataJSON: Record<string, any> = {
      session: session,
    };
    return this.api.requestPOST('guestLogout', dataJSON);
  }

  public setMyauID(session: string, myauid: string, myauid_company: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      myauid: myauid,
      myauid_company: myauid_company,
    };
    return this.api.requestPOST('setMyauID', dataJSON);
  }

  public getMyauID(session: string) {
    let dataJSON: Record<string, any> = {
      session: session,
    };
    return this.api.requestPOST('getMyauID', dataJSON);
  }

  public checkUsername(username: string) {
    let dataJSON: Record<string, any> = {
      username: username,
    };
    return this.api.requestPOST('checkUsername', dataJSON);
  }

  public registerUser(
    session: string,
    username: string,
    password: string,
    pin: string,
    referral: string,
    email: string,
    mobilePhone: string,
    mobilePhoneCountry: string,
    bank: string,
    account_name: string,
    account_number: string
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      username: username,
      password: password,
      pin: pin,
      referral: referral,
      email: email,
      mobile_phone: mobilePhone,
      mobile_phone_country: mobilePhoneCountry,
      bank: bank,
      account_name: account_name,
      account_number: account_number,
    };
    return this.api.requestPOST('registerUser', dataJSON);
  }

  public checkBalance(session: string, id: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
    };
    return this.api.requestPOST('checkBalance', dataJSON);
  }

  public getDepositConfig(session: string) {
    let dataJSON: Record<string, any> = {
      session: session
    };
    return this.api.requestPOST('getDepositConfig', dataJSON);
  }

  public cancelTransactionAutoDetail(session: string, id:string) {
    let dataJSON: Record<string, any> = {
      session: session,
      id:id
    };
    return this.api.requestPOST('cancelTransactionAutoDetail', dataJSON);
  }

  public checkTransactionAuto(session: string, id:string) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id
    };
    return this.api.requestPOST('checkTransactionAuto', dataJSON);
  }

  public checkActiveTransactionAuto(session: string) {
    let dataJSON: Record<string, any> = {
      session: session
    };
    return this.api.requestPOST('checkActiveTransactionAuto', dataJSON);
  }
  
  public checkActiveTransactionManual(session: string) {
    let dataJSON: Record<string, any> = {
      session: session
    };
    return this.api.requestPOST('checkActiveTransactionManual', dataJSON);
  }

  public setTransactionAutoDeposit(session: string, method: string, amount: any, promotion_type: string, promotion_id:string) {
    let dataJSON: Record<string, any> = {
      session: session,
      method: method,
      amount: amount,
    };
    if (promotion_type !== '') {
      dataJSON['promotion_type'] = promotion_type;
    }

    if (promotion_id !== '') {
      dataJSON['promotion_id'] = promotion_id;
    }
    return this.api.requestPOST('setTransactionAutoDeposit', dataJSON);
  }

  public getIntegrationConfig(session: string) {
    let dataJSON: Record<string, any> = {
      session: session
    };
    return this.api.requestPOST('getIntegrationConfig', dataJSON);
  }

  public getIntegrationListQR(session: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      query: { type: 7 },
    };
    return this.api.requestPOST('getIntegrationListQR', dataJSON);
  }

  public getIntegrationQR(session: string, amount: string, transaction_note: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      amount: amount,
      transaction_note: transaction_note
    };
    return this.api.requestPOST('getIntegrationQR', dataJSON);
  }

  public cancelIntegrationTransaction(session: string, id: string, transaction_note: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
      transaction_note: transaction_note
    };
    return this.api.requestPOST('cancelIntegrationTransaction', dataJSON);
  }

  public getPromoCode(code: string) {
    let dataJSON: Record<string, any> = {
      code: code,
    };
    return this.api.requestPOST('getPromoCode', dataJSON);
  }
  public getPromoCodeBySource(
    code: string,
    session: string,
    source: string,
    medium: string,
  ) {
    let dataJSON: Record<string, any> = {
      code: code,
      session: session,
      source: source,
      medium: medium,
    };
    return this.api.requestPOST('getPromoCode', dataJSON);
  }

  public getPromoListBySource(
    session: string,
    source: string, //desktop,mobile,apk
    medium: string //empty/null, search
  ): Observable<any> {
    let dataJSON: Record<string, any> = {
      session: session,
      source: source,
      medium: medium,
    };
    return this.api.requestPOST('promolist', dataJSON);
  }

  public updateImageDeposit(
    session: string,
    id:string,
    transfer_slip: string
  ): Observable<any> {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
      transfer_slip: transfer_slip,
    };
    return this.api.requestPOST('updateImageDeposit', dataJSON);
  }

  public updateNoteDeposit(
    session: string,
    id:string,
    transaction_note: string
  ): Observable<any> {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
      transaction_note: transaction_note,
    };
    return this.api.requestPOST('updateImageDeposit', dataJSON);
  }

  public createDeposit(
    session: string,
    bank_account: string,
    company_bank_account: string,
    amount: string,
    transaction_date: string,
    transfer_slip: string,
    transaction_note: string,
    promotion_type: string,
    promotion_id: string
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      bank_account: bank_account,
      company_bank_account: company_bank_account,
      amount: amount,
      transaction_date: transaction_date,
      transfer_slip: transfer_slip,
      transaction_note: transaction_note,
    };

    if (promotion_type !== '') {
      dataJSON['promotion_type'] = promotion_type;
    }

    if (promotion_id !== '') {
      dataJSON['promotion_id'] = promotion_id;
    }

    return this.api.requestPOST('createDeposit', dataJSON);
  }

  public createWithdraw(
    session: string,
    bank_account: string,
    amount: string,
    transaction_note: string
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      bank_account: bank_account,
      amount: amount,
      transaction_note: transaction_note,
    };

    return this.api.requestPOST('createWithdraw', dataJSON);
  }

  public createDepositQr(
    session: string,
    company_bank_account: string,
    amount: string,
    transaction_date: string,
    transaction_note: string
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      company_bank_account: company_bank_account,
      amount: amount,
      transaction_date: transaction_date,
      transaction_note: transaction_note,
    };

    return this.api.requestPOST('createDepositQr', dataJSON);
  }

  public createAccountBank(
    session: string,
    bank: string,
    account_name: string,
    account_number: string,
    id: string
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      bank: bank,
      account_name: account_name,
      account_number: account_number,
      status: "true",
      id: id,
    };

    return this.api.requestPOST('createAccountBank', dataJSON);
  }

  public getJackpotGlobal() {
    let dataJSON: Record<string, any> = {};
    return this.api.requestPOST('getJackpotGlobal', dataJSON);
  }

  public getBankPlayerList(session: string, id: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      with_deleted: "false",
      id: id,
    };

    return this.api.requestPOST('getBankPlayerList', dataJSON);
  }

  public getBankCompanyList(session: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      query: { status: true },
    };

    return this.api.requestPOST('getBankCompanyList', dataJSON);
  }

  public getBankCompanyAccount(session: string, group:string) {
    let dataJSON: Record<string, any> = {
      query: { group:group }
    };
    if (session !== '') {
      dataJSON['session'] = session;
    }

    return this.api.requestPOST('getBankCompanyAccount', dataJSON);
  }

  public getBankPlayer(session: string) {
    let dataJSON: Record<string, any> = {
      session: session,
    };

    return this.api.requestPOST('getBankPlayer', dataJSON);
  }

  public getBankPlayerSingle(session: string) {
    let dataJSON: Record<string, any> = {
      session: session,
    };

    return this.api.requestPOST('getBankPlayerSingle', dataJSON);
  }

  public getBankPlayerInfo(session: string, bank_account: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      bank_account: bank_account,
    };

    return this.api.requestPOST('getBankPlayerInfo', dataJSON);
  }

  public searchGames(
    session: string,
    queryText: string,
    game_type: Array<any>,
    provider_id: Array<any>,
    tags: Array<any>,
    pagination: Record<string, any>,
    sort: Record<string, any>
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      query_text: queryText,
      pagination: pagination,
      sort: sort,
    };

    if (game_type.length !== 0) {
      dataJSON['game_type'] = JSON.stringify(game_type);
    }
    if (provider_id.length !== 0) {
      dataJSON['provider_id'] = JSON.stringify(provider_id);
    }
    if (tags.length !== 0) {
      dataJSON['tags'] = JSON.stringify(tags);
    }

    return this.api.requestPOST('searchGames', dataJSON);
  }

  public getGames(
    session: string,
    type: string,
    query: Record<string, unknown>,
    pagination: Record<string, unknown>,
    sort: Record<string, unknown>
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      type: type,
      query: query,
      pagination: pagination,
      sort: sort,
    };

    return this.api.requestPOST('getGames', dataJSON);
  }

  public getGameDetails(session: string, id: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
    };

    return this.api.requestPOST('getGameDetails', dataJSON);
  }

  public getRecentWinners(provider: string) {
    let dataJSON: Record<string, any> = {
      provider: provider,
    };
    return this.api.requestPOST('getRecentWinners', dataJSON);
  }

  public launchGame(
    session: string,
    provider: string,
    lobby: string,
    game: string,
    mobile: boolean
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      provider: provider,
      lobby: lobby,
      game: game
    };
    if(mobile){
      dataJSON['mobile'] = 'true';
    }
    return this.api.requestPOST('launchGame', dataJSON);
  }

  public getTransaction(
    session: string,
    query: Record<string, unknown>,
    pagination: Record<string, unknown>
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      query: query,
      pagination: pagination,
    };
    return this.api.requestPOST('getTransaction', dataJSON);
  }

  public getTransactionById(session: string, id: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
    };
    return this.api.requestPOST('getTransactionById', dataJSON);
  }

  public getBalanceHistory(
    session: string,
    id: string,
    query: Record<string, unknown>,
    pagination: Record<string, unknown>
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      query: query,
      pagination: pagination,
      id: id,
    };
    return this.api.requestPOST('getBalanceHistory', dataJSON);
  }

  public getStatementMonthly(session: string, query: Record<string, unknown>) {
    let dataJSON: Record<string, any> = {
      session: session,
      query: query,
    };
    return this.api.requestPOST('getStatementMonthly', dataJSON);
  }

  public getStatementProvider(session: string, query: Record<string, unknown>) {
    let dataJSON: Record<string, any> = {
      session: session,
      query: query,
    };
    return this.api.requestPOST('getStatementProvider', dataJSON);
  }

  public getStatementDetail(
    session: string,
    query: Record<string, unknown>,
    pagination: Record<string, unknown>
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      query: query,
      pagination: pagination,
    };
    return this.api.requestPOST('getStatementDetail', dataJSON);
  }

  public getStatementResult(
    session: string,
    id: string,
    query: Record<string, unknown>,
    pagination: Record<string, unknown>
  ) {
    let dataJSON: Record<string, any> = {
      session: session,
      query: query,
      pagination: pagination,
      id: id,
    };
    return this.api.requestPOST('getStatementResult', dataJSON);
  }

  public getRebate() {
    let dataJSON: Record<string, any> = {};
    return this.api.requestPOST('getRebate', dataJSON);
  }

  public getReferralPlayer(session: string, id: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
    };
    return this.api.requestPOST('getReferralPlayer', dataJSON);
  }

  public claimReferral(session: string, claimAmount: string, id: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      claim_amount: claimAmount,
      id: id,
    };
    return this.api.requestPOST('claimReferral', dataJSON);
  }

  public claimedReferral(session: string, pagination: Record<string, unknown>) {
    let dataJSON: Record<string, any> = {
      session: session,
      pagination: pagination,
    };
    return this.api.requestPOST('claimedReferral', dataJSON);
  }

  public getReferralDownline(session: string, id: string, pagination: Record<string, unknown>) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
      pagination: pagination,
    };
    return this.api.requestPOST('getReferralDownline', dataJSON);
  }

  public editReferral(session: string, referralCode: string, id: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      referral_code: referralCode,
      id: id,
    };
    return this.api.requestPOST('editReferral', dataJSON);
  }

  public getReferral(session: string) {
    let dataJSON: Record<string, any> = {};

    if (session !== '') {
      dataJSON['session'] = session;
    }

    return this.api.requestPOST('getReferral', dataJSON);
  }

  public getSpinwheelSetting() {
    let dataJSON: Record<string, any> = {};

    return this.api.requestPOST('getSpinwheelSetting', dataJSON);
  }

  public checkReferral(
    referral_code: string,
  ) {
    let dataJSON: Record<string, any> = {
      referral_code: referral_code
    };

    return this.api.requestPOST('checkReferral', dataJSON);
  }

  public getSpinwheelParticipant(session: string, id: string) {
    let dataJSON: Record<string, any> = {
      session: session,
      id: id,
    };

    return this.api.requestPOST('getSpinwheelParticipant', dataJSON);
  }

  public getLivescore() {
    let dataJSON: Record<string, any> = {
    };

    return this.api.requestPOST('getLivescore', dataJSON);
  }
}
