import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, CurrencyPipe, isPlatformBrowser } from '@angular/common';
import { FooterComponent } from '@template/green/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCircleQuestion, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faCircleCheck, faCircleChevronRight, faCircleInfo, faCirclePlus, faCreditCard, faImages, faMagnifyingGlass, faMoneyCheckDollar, faShield, faSliders, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperOptions } from 'swiper/types';
import { A11y, Grid, Mousewheel } from 'swiper/modules';
import { SwiperDirective } from '@componentpath/lib/swiper.directive';
import { DepositManualComponent } from '@template/green/parts/deposit-manual/deposit-manual.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DepositMobileComponent } from '@template/green/mobile/deposit-mobile/deposit-mobile.component';
import { HeaderSimpleComponent } from '@template/green/header-simple/header-simple.component';
import { QRCodeModule } from 'angularx-qrcode';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SafeUrl } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@template/green/snackbar/snackbar.component';
import { SplitNumberPipe } from '@componentpath/pipes/format-number-id.pipe';
import { OrderByPipe } from '@componentpath/pipes/slug-name.pipe';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { ScriptsService } from '@componentpath/services/scripts.service';

@Component({
  selector: 'app-deposit-desktop',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, HeaderSimpleComponent, OrderByPipe, FooterComponent, QRCodeModule, ReactiveFormsModule, FormsModule, FontAwesomeModule, NgbDropdownModule, CurrencyPipe, SwiperDirective, SplitNumberPipe],
  templateUrl: './deposit-desktop.component.html',
  styleUrl: './deposit-desktop.component.scss'
})
export class DepositDesktopComponent {

  subscription: Subscription = new Subscription();

  //font awesome
  faCircleQuestion = faCircleQuestion;
  faCircleChevronRight = faCircleChevronRight;
  faTrashCan = faTrashCan;
  faCircleInfo = faCircleInfo;
  faMagnifyingGlass = faMagnifyingGlass;
  faCircleCheck = faCircleCheck;
  faCheck = faCheck;
  faShield = faShield;
  faXmark = faXmark;
  faSliders = faSliders;
  faCirclePlus = faCirclePlus;
  faMoneyCheckDollar = faMoneyCheckDollar;
  faCreditCard = faCreditCard;
  faUser = faUser;
  faImages = faImages;
  typings:any;

  statusPage = "form" //success, confirm, form, payment, promo;

  userProfile: any;
  currentMethodPayment: string = 'qris';
  currentActiveBank: any = {};
  currentActiveAccount: string = '';
  currentBankManual: any;
  inputAmount: any = null;
  currentNominal: number = 0;
  userGroupData: Record<string, any> = {};
  bankCompanyList: any = [];
  bankUserList: any = [];
  depositConfig: Record<string, any> = {};
  loader: boolean = true;

  currentSelectPaymentType: string = 'all';
  balance: any = undefined;
  promoList: Record<string, any>[] = [];
  noteDeposit = new FormControl('', []);
  searchPromo = new FormControl('', []);
  promoSearch: Record<string, any>[] = [];
  currentPromo: Record<string, any> = {};
  selectedPromo: Record<string, any> = {};
  // showPromo: boolean = false;
  showNotifText: string = '';
  showNotif: boolean = false;
  showError: boolean = false;
  disabledButton: boolean = true;
  disabledButtonCreate: boolean = false;
  disabledButtonSub: boolean = false;
  inProgress: boolean = false;
  autoData: any;
  manualData: any;
  qrCodeDownloadLink: SafeUrl = '';
  websiteJSONData: any = this.data.websiteJSONData;
  timeCountSecond: number = 5;
  isCorrectValue: number = 0;
  intervalStatus: any;

  //data manual dp
  bankListA: Record<string, any>[] = [];
  bankListB: Record<string, any>[] = [];
  bankListC: Record<string, any>[] = [];
  bankListALL: Record<string, any>[] = [];
  bankList: Record<string, any>[] = [];
  bankListCreateA: Record<string, any>[] = [];
  bankListCreateC: Record<string, any>[] = [];
  bankListCreateALL: Record<string, any>[] = [];
  isCreatedBank: boolean = false;
  currentCreatedBank: Record<string, any> = {};
  listSelectCreateBank: Record<string, any>[] = [];
  listSelectCreateEwallet: Record<string, any>[] = [];
  currentSelectCreateBank: string = 'bank';
  currentSelectedCreateBank: Record<string, any> = {};
  currentDataBankAdmin: Record<string, any> = {};
  currentDataBankUser: Record<string, any> = {};
  currentSelectDataBankUser: Record<string, any> = {};
  currentSelectDataBankAdmin: Record<string, any> = {};
  currentFee: number = 0;
  optionsBank: Array<any> = [];
  searchedOptions: Array<any> = [];
  searchedOptionsCompany: Array<any> = [];
  searchedCreateOptions: Array<any> = [];
  listSelectBankUser: Record<string, any>[] = [];
  listSelectBankUserBank: Record<string, any>[] = [];
  listSelectBankUserEwall: Record<string, any>[] = [];
  listSelectBankUserQris: Record<string, any>[] = [];
  listSelectBankUserCell: Record<string, any>[] = [];
  transactionConfig: Record<string, any> = {};
  searchBankInput: string = '';
  inputSearchCreate: string = '';
  inputUserBankNum: string = '';
  inputUserBankName: string = '';
  amountDepositNumber: number = 0;
  minDPAmount: number = 0;
  maxDPAmount: number = 0;

  base64ImageData: any = '';
  imageFileName: string = '';


  public minutes: number = 0;
  public seconds: number = 0;
  public minutesShow: string = '';
  public secondsShow: string = '';
  private intervalId: any;

  arrayNominal: Array<number> = [];

  public config: SwiperOptions = {
    init: true,
    modules: [A11y, Mousewheel, Grid],
    slidesPerView: 'auto',
    spaceBetween: 10,
    grabCursor: false,
    loop: false,
    mousewheel: true,
    freeMode: true,

  };

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  currentScreenSize: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private requestService: RequestService,
    private router: Router,
    private route: ActivatedRoute,
    private request: RequestService,
    private cookie: SsrCookieService,
    private scriptsService: ScriptsService,
    private snackBar: MatSnackBar
  ) {}

  setArrayNominal() {
    this.arrayNominal = [];
    let defaultArr = [10000, 20000, 50000, 100000, 500000, 1000000, 5000000, 10000000];
      if(this.currentMethodPayment === 'bank'){
        // currentDataBankAdmin['system_bank'] ? currentDataBankAdmin['system_bank']['min_deposit_amount'] > 0 ? (currentDataBankAdmin['system_bank']['min_deposit_amount'] | currency:' ' : 'symbol' : '1.0-0') : (minDPAmount | currency:' ' : 'symbol' : '1.0-0') :''
        for (let i = 0; i < defaultArr.length; i++) {
          const element = defaultArr[i];
          if (element >= this.minDPAmount && element <= this.maxDPAmount) {
            this.arrayNominal.push(element);
          } else {
            if (element < this.minDPAmount) {
              // if (this.arrayNominal.includes(this.minDPAmount) === false) this.arrayNominal.push(this.minDPAmount);
            }
          }
          if (defaultArr.length === i + 1) {
            if (this.arrayNominal.includes(this.maxDPAmount) === false) this.arrayNominal.push(this.maxDPAmount);
          }
        }

      } else if (this.currentMethodPayment === 'vacc') {
        for (let i = 0; i < defaultArr.length; i++) {
          const element = defaultArr[i];
          if (element >= this.depositConfig['virtual_account_min_amount'] && element <= this.depositConfig['virtual_account_max_amount']) {
            this.arrayNominal.push(element);
          } else {
            if (element < this.depositConfig['virtual_account_min_amount']) {
              if (this.arrayNominal.includes(this.depositConfig['virtual_account_min_amount']) === false) this.arrayNominal.push(this.depositConfig['virtual_account_min_amount']);
            }
          }
          if (defaultArr.length === i + 1) {
            if (this.arrayNominal.includes(this.depositConfig['virtual_account_max_amount']) === false) this.arrayNominal.push(this.depositConfig['virtual_account_max_amount']);
          }
        }
      }
      else {
        for (let i = 0; i < defaultArr.length; i++) {
          const element = defaultArr[i];
          if (element >= this.depositConfig['qris_min_amount'] && element <= this.depositConfig['qris_max_amount']) {
            this.arrayNominal.push(element);
          } else {
            if (element < this.depositConfig['qris_min_amount']) {
              if (this.arrayNominal.includes(this.depositConfig['qris_min_amount']) === false) this.arrayNominal.push(this.depositConfig['qris_min_amount']);
            }
          }
          if (defaultArr.length === i + 1) {
            if (this.arrayNominal.includes(this.depositConfig['qris_max_amount']) === false) this.arrayNominal.push(this.depositConfig['qris_max_amount']);
          }
      }
    }
  }

  updateNote() {
    if (isPlatformBrowser(this.platformId)) {
      if(this.typings){
        clearTimeout(this.typings);
      }
      this.typings = setTimeout(() => {
        if (this.manualData) {
          let noteValue = this.noteDeposit.value;
          if (noteValue === null) {
            noteValue = '';
          }
          this.requestService.updateNoteDeposit(this.auth.sessionValue, this.manualData['_id'], noteValue).subscribe({
            next: (v) => {
              const value = v['data'] as Record<string, any>;
              if (v['code'] === 200) {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: { message: 'Berhasil Update Catatan', type: 'mobile-default' },
                  duration: 3000,
                  horizontalPosition: "center",
                  verticalPosition: "top",
                  panelClass: "notification-success"
                });
              }
            },
            error: (e) => {
              this.snackBar.openFromComponent(SnackbarComponent, {
                data: { message: e.error.messages[0].message, type: 'error' },
                duration: 3000,
                verticalPosition: 'top',
                panelClass: "notification-error"
              });
            },
            complete: () => { }
          })
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: 'Catatan gagal di update', type: 'error' },
            duration: 3000,
            verticalPosition: 'top',
            panelClass: "notification-error"
          });
        }
      }, 2000);
    }
  }

  getBase64(file: any, resize: boolean, sizeKB: number) {
    var scale = 0.7;

    if (sizeKB > 10000) {
      scale = 0.3;
    }
    if (sizeKB > 5000 && sizeKB < 10000) {
      scale = 0.4;
    }
    if (sizeKB > 3000 && sizeKB < 5000) {
      scale = 0.5;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (resize) {
        this.base64Resize(reader.result, scale);
      } else {
        this.base64ImageData = reader.result;
      }
      this.uploadImage();
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  uploadImage(){
    if(this.manualData){
      this.requestService.updateImageDeposit(this.auth.sessionValue, this.manualData['_id'], this.base64ImageData).subscribe({
        next: (v) => {
          const value = v['data'] as Record<string, any>;
          if (v['code'] === 200) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: { message: 'Image Berhasil di Upload', type: 'mobile-default' },
              duration: 3000,
              horizontalPosition: "center",
              verticalPosition: "top",
              panelClass: "notification-success"
            });
          }
        },
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: e.error.messages[0].message, type: 'error' },
            duration: 3000,
            verticalPosition: 'top',
            panelClass: "notification-error"
          });
        },
        complete: () => { }
      })
    } else{
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Image Gagal di Upload', type: 'error' },
        duration: 3000,
        verticalPosition: 'top',
        panelClass: "notification-error"
      });
    }
  }

  base64Resize(sourceBase64: any, scale: any) {

    const _scale = scale;
    var img = document.createElement('img');
    img.setAttribute("src", sourceBase64);

    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.width * _scale;
      canvas.height = img.height * _scale;

      var ctx = canvas.getContext("2d");
      var cw = canvas.width;
      var ch = canvas.height;
      var maxW = img.width * _scale;
      var maxH = img.height * _scale;

      var iw = img.width;
      var ih = img.height;
      var scl = Math.min((maxW / iw), (maxH / ih));
      var iwScaled = iw * scl;
      var ihScaled = ih * scl;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx!.drawImage(img, 0, 0, iwScaled, ihScaled);
      const newBase64 = canvas.toDataURL("image/jpeg", scl);
      // console.log(newBase64);
      this.base64ImageData = newBase64;
      return newBase64;
    }
  }

  onSelectFile(e: any) {
    // console.log(e)
    if (e.target.files.length > 0) {
      console.log(e)
      this.imageFileName = e.target.files[0].name;
      let sizeKB = e.target.files[0].size / 1024;
      let fileType = e.target.files[0].type;
      // console.log('File Type :', fileType);
      // console.log('Size :', e.target.files[0].size / 1024);
      // console.log(e.target.files[0].name)
      if (sizeKB > 1500) {
        this.getBase64(e.target.files[0], true, sizeKB);
      } else {
        if (fileType !== "image/png" && fileType !== "image/jpeg") {
          // this.uploadImage.setErrors({ 'invalidFileType': true });
          // this.uploadImage.markAllAsTouched();
          // console.log(this.uploadImage.touched)
        } else {
          this.getBase64(e.target.files[0], false, sizeKB);
        }
      }
    } else {
      this.imageFileName = 'File Not Selected';
      this.base64ImageData = '';
    }

  }

  getBankCompanyByGroup() {
    this.requestService.getBankCompanyAccount(this.auth.sessionValue, this.auth.userGorupData['_id']).subscribe({
      next: (v) => {
        const value = v['data'] as Record<string, any>;
        if (value) {
          console.log(value);
          value['records'] = value['records'].filter((option: any) =>
            option['status']
          );
          this.bankCompanyList = value['records'];
          this.bankCompanyList.sort((a: any, b: any) => parseFloat(a['system_sort']) - parseFloat(b['system_sort']) || (a["system_bank"]["name"]).toLowerCase().localeCompare(b["system_bank"]["name"].toLowerCase()));
          // console.log(this.bankCompanyList[0]);
          this.loader = false;
          if (this.statusPage === 'confirm-bank' && this.currentBankManual){
            this.selectActiveBank(this.currentBankManual);
          } else {
            this.selectActiveBank(this.bankCompanyList[0]);
          }
        }
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => { }
    })
  }

  selectMethodPayment(val: string) {
    if (this.statusPage === 'form'){
      this.currentMethodPayment = val;
      if (this.currentMethodPayment !== 'bank'){
        this.setArrayNominal();
        if (this.inputAmount){
          this.updateValueNominal(this.inputAmount.toString());
        }
      }
    }
    // this.checkActiveDepositAuto();
  }
  selectActiveBank(val: any) {
    if (this.statusPage === 'form') {
      this.currentActiveBank = val;
      this.currentActiveAccount = val._id;
      if(val.system_bank.min_deposit_amount){
        this.minDPAmount = val.system_bank.min_deposit_amount;
      }else{
        this.minDPAmount = this.data.transactionConfigJSONData['min_deposit_amount'];
      }
      if(val.system_bank.max_deposit_amount){
        this.maxDPAmount = val.system_bank.max_deposit_amount;
      } else {
        this.maxDPAmount = this.data.transactionConfigJSONData['max_deposit_amount'] > this.minDPAmount ? this.data.transactionConfigJSONData['max_deposit_amount'] : 10000000;
      }
      this.setArrayNominal();
      if (this.inputAmount) {
        this.updateValueNominal(this.inputAmount.toString());
      }
    }
    if(this.statusPage === 'confirm-bank'){
      this.currentMethodPayment = 'bank';
      console.log(val)
      this.currentActiveBank = val;
      this.currentActiveAccount = val._id;
      if (val.system_bank.min_deposit_amount) {
        this.minDPAmount = val.system_bank.min_deposit_amount;
      } else {
        this.minDPAmount = this.data.transactionConfigJSONData['min_deposit_amount'];
      }
      if (val.system_bank.max_deposit_amount) {
        this.maxDPAmount = val.system_bank.max_deposit_amount;
      } else {
        this.maxDPAmount = this.data.transactionConfigJSONData['max_deposit_amount'] > this.minDPAmount ? this.data.transactionConfigJSONData['max_deposit_amount'] : 10000000;
      }
      this.setArrayNominal();
      if (this.inputAmount) {
        this.updateValueNominal(this.inputAmount.toString());
      }
    }
  }

  selectedBankUser(val: any) {
    this.currentSelectDataBankUser = val;
  }

  onSelectDropdownValue(e: any): void {
    console.log(e);
    if (e === 'all') {
      this.searchedOptions = this.listSelectBankUser;
    }
    if (e === 'bank') {
      this.searchedOptions = this.listSelectBankUserBank;
    }
    if (e === 'ewallet') {
      this.searchedOptions = this.listSelectBankUserEwall;
    }
    if (e === 'cellular') {
      this.searchedOptions = this.listSelectBankUserCell;
    }
    if (e === 'qris') {
      this.searchedOptions = this.listSelectBankUserQris;
    }
    this.currentSelectPaymentType = e;
  }

  onSearchDropdownValue(event: Event): void {
    const input = this.searchBankInput.toLowerCase();
    let typeLists: Array<any> = [];
      if (this.currentSelectPaymentType === 'all') {
        typeLists = this.listSelectBankUser;
      }
      if (this.currentSelectPaymentType === 'bank') {
        typeLists = this.listSelectBankUserBank;
      }
      if (this.currentSelectPaymentType === 'ewallet') {
        typeLists = this.listSelectBankUserEwall;
      }
      if (this.currentSelectPaymentType === 'cellular') {
        typeLists = this.listSelectBankUserCell;
      }
      if (this.currentSelectPaymentType === 'qris') {
        typeLists = this.listSelectBankUserQris;
      }
      this.searchedOptions = typeLists.filter((option) =>
        (option['system_bank']['name'].toLowerCase().includes(input) || (option['account_number']).includes(input) || (option['account_name']).toLowerCase().includes(input))
      );

      if (!input) {
        this.searchedOptions = [...typeLists];
      }
  }

  cancelPage(){
    this.statusPage = 'form';
  }

  selectPayment(){
    this.currentDataBankUser = this.currentSelectDataBankUser;
    this.statusPage = 'form';
    this.showNotif = false;
  }

  selectCreateBankOption() {
    this.statusPage = 'create-bank';
    this.showNotif = false;
  }

  changeAmount() {
    if (this.inputAmount !== undefined) {
      this.amountDepositNumber = parseInt(this.inputAmount);

      let fixFee = 0;
      let percentFee = 0;
      if (this.currentActiveBank['system_bank']['transaction_fee']) {
        fixFee = parseInt(this.currentActiveBank['system_bank']['transaction_fee']);
      }
      if (this.currentActiveBank['system_bank']['transaction_fee_percentage']) {
        percentFee = parseFloat(this.currentActiveBank['system_bank']['transaction_fee_percentage']);
      }

      let percentFeeValue = percentFee * parseInt(this.inputAmount);
      this.currentFee = percentFeeValue > fixFee ? percentFeeValue : fixFee;
    }
  }

  setNominalChip(val: number) {
    this.currentNominal = val;
    this.inputAmount = this.currentNominal;
    this.disabledButton = false;
    // this.isCorrectValue = val % 1000;
    this.changeAmount();
  }

  updateValueNominal(e: any) {
    let rawNominal = e.split('.').join("");
    this.inputAmount = parseInt(rawNominal);
    // this.isCorrectValue = this.inputAmount % 1000;
    this.isCorrectValue = 0;
    this.setNominalChip(this.inputAmount);
    if (Number.isNaN(this.inputAmount)) {
      // console.log(this.inputAmount)
      this.disabledButton = true;
      this.isCorrectValue = 0;
    } else {
      // console.log(e);
      if(this.currentMethodPayment == 'bank'){
        if (this.inputAmount >= this.minDPAmount && this.inputAmount <= this.maxDPAmount) {
          this.disabledButton = false;
        } else {
          this.disabledButton = true;
          this.isCorrectValue = 1;
        }
      }
      if(this.currentMethodPayment == 'qris'){
        if ((this.inputAmount * 1000) >= this.depositConfig['qris_min_amount'] && (this.inputAmount * 1000) <= this.depositConfig['qris_max_amount']) {
          this.disabledButton = false;
        } else {
          this.disabledButton = true;
          this.isCorrectValue = 1;
        }
      }
      if(this.currentMethodPayment == 'vacc'){
        if ((this.inputAmount * 1000) >= this.depositConfig['virtual_account_min_amount'] && (this.inputAmount * 1000) <= this.depositConfig['virtual_account_max_amount']) {
          this.disabledButton = false;
        } else {
          this.disabledButton = true;
          this.isCorrectValue = 1;
        }
      }
    }
    console.log(this.isCorrectValue);
  }

  confirmDeposit(val:string) {
    if(val === 'bank'){
      this.setDepositManual();
    }else{
      this.setDepositAuto();
    }
  }

  selectPromo() {
    this.showNotif = false;
    // this.showPromo = false;
    this.statusPage = 'form';
    if (this.selectedPromo['_id']) {
      this.currentPromo = this.selectedPromo;
    }
  }

  selectBankCreate(e: string) {
    this.currentSelectCreateBank = e;
    if (e === 'bank') {
      this.searchedCreateOptions = this.listSelectCreateBank;
    } else {
      this.searchedCreateOptions = this.listSelectCreateEwallet;
    }
    this.currentSelectedCreateBank = this.searchedCreateOptions[0];
    // console.log(this.searchedCreateOptions);
  }

  selectedCreateBank(e: any) {
    this.currentSelectedCreateBank = e;
  }

  createBankAcc() {
    this.disabledButtonCreate = true;
    if (this.inputUserBankNum !== '' && this.inputUserBankNum !== undefined && this.inputUserBankName !== '' && this.inputUserBankName !== undefined) {

      this.showNotif = false;
      this.requestService.createAccountBank(this.auth.sessionValue, this.currentSelectedCreateBank['_id'], this.inputUserBankName as string, this.inputUserBankNum as string, this.auth.userProfileData['_id'] as string).subscribe({
        next: (v) => {
          const value = v as Record<string, unknown>;
          // console.log(value);
          if (value['code'] === 200) {
            const data = value['data'] as Record<string, any>;
            this.loader = true;
            // this.router.navigate(['/deposit']);
            this.isCreatedBank = true;
            this.currentCreatedBank = data;
            this.getBankData();
            this.statusPage = 'form';
            this.disabledButtonCreate = false;
          }
        },
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: e.error.messages[0].message, type: 'error' },
            duration: 3000,
            verticalPosition: 'top',
            panelClass: "notification-error"
          });
          this.disabledButtonCreate = false;
          this.inProgress = false;
        },
        complete: () => { }

      })

    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: 'Error Input, Mohon Check Nomor Rekening Anda', type: 'error' },
        duration: 3000,
        verticalPosition: 'top',
        panelClass: "notification-error"
      });
      this.disabledButtonCreate = false;
    }
  }

  onSearchcreateValue(e: Event) {
    const input = this.inputSearchCreate.toLowerCase();
    let typeLists: Array<any> = [];

    if (this.currentSelectCreateBank === 'bank') {
      typeLists = this.listSelectCreateBank;
    }
    if (this.currentSelectCreateBank === 'ewallet') {
      typeLists = this.listSelectCreateEwallet;
    }

    this.searchedCreateOptions = typeLists.filter((option) =>
      (option['name'].toLowerCase().includes(input))
    );

    if (!input) {
      this.searchedCreateOptions = [...typeLists];
    }
  }

  openPromoSearch() {
    this.showNotif = false;
    // this.showPromo = true;
    this.statusPage = 'promo'
  }

  resetPromo() {
    this.selectedPromo = {};
    this.currentPromo = {};
  }

  resetPromoBack() {
    if (this.currentPromo['_id']) {
      // this.showPromo = false;
      this.statusPage = 'form';
    } else {
      this.selectedPromo = {};
      // this.showPromo = false;
      this.statusPage = 'form';
    }
  }

  getPromoList() {
    this.requestService.getPromoListBySource(this.auth.sessionValue, 'desktop', '').subscribe({
      next: (v) => {
        const value = v as Record<string, any>;
        if (value['data']) {
          this.promoList = value['data'];
          this.promoSearch = this.promoList;
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  inputSearchPromo() {
    let codePromo = this.searchPromo.value;
    if (codePromo !== '' && codePromo !== undefined && codePromo !== null) {
      this.requestService.getPromoCode(codePromo).subscribe({
        next: (v) => {
          // console.log(v);
          const value = v as Record<string, any>;
          this.promoSearch = [];
          this.promoSearch.push(value['data']);
        },
        error: (e) => {
          console.log(e);
          this.showNotif = true;
          this.showNotifText = e.error.messages[0].message;
        },
        complete: () => { }
      })
    } else {
      this.promoSearch = this.promoList;
    }
  }

  // getBankCompany() {
  //   this.request.getBankCompanyList(this.auth.sessionValue).subscribe({
  //     next: (val) => {
  //       // console.log(val);
  //       if (val['code'] === 200) {
  //         if (val['data'][this.userGroupData['rank']] !== undefined) {
  //           this.bankCompanyList = val['data'][this.userGroupData['rank']];
  //         } else {
  //           this.bankCompanyList = val['data'][1];
  //         }
  //         // console.log(this.bankCompanyList);
  //       }
  //     },
  //     error: (e) => {
  //       console.log(e);
  //       this.snackBar.openFromComponent(SnackbarComponent, {
  //         data: { message: e.error.messages[0].message, type: 'error' },
  //         duration: 3000,
  //         verticalPosition: 'top',
  //         panelClass: "notification-error"
  //       });
  //       this.disabledButtonSub = false
  //     },
  //     complete: () => { }
  //   })
  // }

  getBankData() {
    this.loader = true;
    this.bankListA = [];
    this.bankListC = [];
    this.bankListALL = [];
    let value = this.data.bankRegisterJSONData['records'] as any[]
    value.sort((a, b) => parseFloat(a['sort']) - parseFloat(b['sort']) || a["name"].localeCompare(b["name"]));
    // console.log(value);
    this.bankListALL = value.filter((option) =>
      ((option['type'] === 1 || option['type'] === 2) && option['code'] !== 'oth' && option['status'])
    );
    this.bankListA = value.filter((option) =>
      ((option['type'] !== 1) && option['code'] !== 'oth' && option['status'])
    );
    this.bankListC = value.filter((option) =>
      ((option['type'] === 1) && option['code'] !== 'oth' && option['status'])
    );

    this.requestService.getBankPlayerList(this.auth.sessionValue, this.auth.userProfileData['_id'] as string).subscribe({
      next: (v) => {
        // console.log(v);
        const value = v as Record<string, any>;
        if (value['code'] === 200) {
          value['data']['records'] = value['data']['records'].filter((option: any) =>
            option['status']
          );
          this.bankListB = value['data']['records'];
          this.bankList = this.bankListB;
          // this.bankList.sort((a, b) => parseFloat(a['system_bank']['sort']) - parseFloat(b['system_bank']['sort']));
          this.bankListCreateA = this.bankListA.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          this.bankListCreateC = this.bankListC.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          this.bankListCreateALL = this.bankListALL.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          // this.bankListCreateC.sort(function (a: any, b: any) {
          //   return a.name.localeCompare(b.name)
          // });
          // this.bankListCreateALL.sort(function (a: any, b: any) {
          //   return a.name.localeCompare(b.name)
          // });

          if (this.isCreatedBank) {
            let indexSelected = this.bankList.findIndex(x => (x['bank'] === this.currentCreatedBank['bank'] && x['account_number'] === this.currentCreatedBank['account_number']));
            this.currentDataBankUser = this.bankList[indexSelected];
          } else {
            if (this.bankList[0]['system_bank']['code'] !== 'qris') {
              this.currentDataBankUser = this.bankList[0];
            } else {
              this.currentDataBankUser = this.bankList[1];
            }
          }
          this.currentSelectDataBankUser = this.currentDataBankUser;

          console.log(this.currentSelectDataBankUser);

          this.listSelectBankUser = this.bankList.filter((option) =>
            option['system_bank']['type'] !== 3 && option['system_bank']['type'] !== 4
          );
          this.searchedOptions = this.listSelectBankUser;
          this.currentSelectedCreateBank = this.bankListCreateC[0];

          this.listSelectCreateBank = this.bankListCreateC.filter((option) =>
            option['type'] === 1
          );
          if (this.transactionConfig['allow_add_ewallet']) {
            this.listSelectCreateEwallet = this.bankListA.filter((option) =>
              option['type'] === 2
            );
          } else {
            this.listSelectCreateEwallet = this.bankListCreateA.filter((option) =>
              option['type'] === 2
            );
          }

          this.searchedCreateOptions = this.listSelectCreateBank;
          console.log(this.searchedCreateOptions);

          this.listSelectBankUserBank = this.bankList.filter((option) =>
            option['system_bank']['type'] === 1
          );
          this.listSelectBankUserEwall = this.bankList.filter((option) =>
            option['system_bank']['type'] === 2
          );

          if (this.isCreatedBank) {
            let indexSelected = this.bankList.findIndex(x => (x['bank'] === this.currentCreatedBank['bank'] && x['account_number'] === this.currentCreatedBank['account_number']));
            this.currentDataBankUser = this.bankList[indexSelected];
          } else {
            if (this.bankList[0]['system_bank']['code'] !== 'qris') {
              this.currentDataBankUser = this.bankList[0];
            } else {
              this.currentDataBankUser = this.bankList[1];
            }
          }
          // this.listSelectBankUser = this.bankList.filter((option) =>
          //   option['system_bank']['type'] !== 3 && option['system_bank']['type'] !== 4
          // );

          this.getBankCompanyByGroup();
        }
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => {
      }
    })
  }

  subscriptionData() {
    this.subscription.add(
      this.auth.getBalancevalue.subscribe({
        next: (v) => {
          let valTrunc = Math.floor(v);
          this.balance = valTrunc;
        },
      })
    );
    if (this.currentScreenSize === 'desktop') {
      this.subscription.add(this.route.queryParams.subscribe((params) => {
        // if (params['p'] === '2') {
        //   this.hideSwitchMenu = true;
        //   if (!this.showFormPaymentAuto && !this.showFormPaymentManual) {
        //     this.router.navigate(['/deposit'])
        //   }
        // } else {
        //   this.hideSwitchMenu = false;
        //   if (!this.showFormPaymentAuto && !this.showFormPaymentManual) {
        //     this.router.navigate(['/deposit'])
        //   }
        // }
      }));
    }
  }

  getDepositConfig() {
    this.request.getDepositConfig(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          this.depositConfig = val['data'] as Record<string, any>;
          this.setArrayNominal();
          // this.depositConfig['status'] = true;
          // this.depositConfig['qris'] = true;
          // this.depositConfig['virtual_account'] = true;
          if (this.depositConfig['status'] === false) {
            this.checkActiveDepositManual();
            // this.showFormPaymentManual = true;
            // this.loader = false;
            // this.currentOptionPayment = "manual";
            this.statusPage = "form";
            this.currentMethodPayment = "bank";
          } else {
            this.checkActiveDepositAuto();
            if ((this.depositConfig['virtual_account'] && !this.depositConfig['qris'])) {
              this.currentMethodPayment = 'vacc';
  
            }
            if (!this.depositConfig['virtual_account'] && this.depositConfig['qris']) {
              this.currentMethodPayment = 'qris'
  
            }
            if (!this.depositConfig['virtual_account'] && !this.depositConfig['qris']) {
              this.currentMethodPayment = 'bank'
            }
          }
          // this.loader =false;
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => { }
    })
  }

  gotoTransaction(){
    this.router.navigate(['/transaction']);
  }

  copyToClipboard(id: string): void {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(id).then(() => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: 'Nomor Rekening berhasil disalin', type: 'mobile-default' },
          duration: 3000,
          horizontalPosition: "center",
          verticalPosition: "top",
          panelClass: "notification-default"
        });
      }).catch(err => {
        console.error('Failed to copy ID to clipboard', err);
      });
    }
  }

  setDepositManual() {
    if (this.isCorrectValue === 0 && this.inputAmount >= this.minDPAmount && this.inputAmount <= this.maxDPAmount) {

      this.disabledButton = true;
      this.inProgress = true;
        let shortDateTime = dayjs().format('DD-MM-YYYY HH:mm:ss');

  
        let noteValue = this.noteDeposit.value;
  
        if (noteValue === null) {
          noteValue = '';
        }
  
        let promoID = '';
        let promoType = '';
        if (this.currentPromo['_id']) {
          promoID = this.currentPromo['_id'];
          promoType = this.currentPromo['type'];
        }
  
        // console.log('CALLED')
  
        this.requestService.createDeposit(this.auth.sessionValue, this.currentDataBankUser['_id'], this.currentActiveBank['_id'], this.inputAmount as string, shortDateTime, '', noteValue, promoType, promoID).subscribe({
          next: (v) => {
            const value = v as Record<string, unknown>;
            if (value['code'] === 200) {
              const data = value['data'] as Record<string, any>;
              this.manualData = value['data'];
              this.manualData['amount'] = parseInt(this.manualData['amount']);
              this.manualData['transaction_fee'] = parseInt(this.manualData['transaction_fee']);
              this.statusPage = 'confirm-bank';
              this.inProgress = false;
              //ads conversion
              const adsCodeMeta = this.websiteJSONData['ads_code'];
              const googleAds = this.websiteJSONData['third_party_code']["google"]['google_tag_manager_id'];
              const cookie_fbp = this.cookie.get('_fbp');
              const cookie_fbc = this.cookie.get('_fbc');
              if (googleAds) {
                this.scriptsService.googleConversionDP(data['amount'], data['_id'], data['selected_promotion'], data['transaction_fee'], data['from_login_id'], this.userProfile.mobile_phone_number, data['to_bank']['system_bank']['name'], data['to_bank']['account_number'], data['to_bank']['account_name'], '');
              }
              for (let i of adsCodeMeta.facebook) {
                this.request.facebookPixel(i.id, i.token, 'Purchase', cookie_fbc, cookie_fbp, data['from_login_id'], this.userProfile.mobile_phone_number, data['amount'], data['_id'], data['to_bank']['system_bank']['name'], data['to_bank']['account_number'], data['to_bank']['account_name'], '').subscribe({
                  next: (response) => {
                    // console.log(response);
                  },
                  error: (error) => { },
                  complete: () => { }
                })
              }
              //------------- end ads conversion -----------//
              // this.router.navigate(['/transaction']);
            }
          },
          error: (e) => {
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: { message: e.error.messages[0].message, type: 'error' },
              duration: 3000,
              verticalPosition: 'top',
              panelClass: "notification-error"
            });
            this.disabledButton = false;
            this.inProgress = false;
          },
          complete: () => {}
        })
      } else {
        this.showNotif = true;
        this.showNotifText = 'Error Input, Mohon check jumlah deposit anda';
        this.disabledButton = false;
      }
    }

  setDepositAuto() {
    if (this.isCorrectValue === 0 && (this.inputAmount * 1000) >= 10000 && (this.inputAmount * 1000) <= 10000000 ) {
      this.disabledButton = true;
      this.inProgress = true;
      let promoID = '';
      let promoType = '';
      if (this.currentPromo['_id']) {
        promoID = this.currentPromo['_id'];
        promoType = this.currentPromo['type'];
      }
      this.request.setTransactionAutoDeposit(this.auth.sessionValue, 'qris', (this.inputAmount * 1000), promoType, promoID).subscribe({
        next: (val) => {
          if (val['code'] === 200) {
            console.log(val);
            this.autoData = val['data'];
            this.autoData['amount'] = parseInt(this.autoData['amount'])
            this.autoData['transaction_fee'] = parseInt(this.autoData['transaction_fee']);
            this.statusPage = 'confirm';
            this.countTime();
            this.checkIntervalStatusDeposit();
            this.inProgress = false;
            //ads conversion
            const adsCodeMeta = this.websiteJSONData['ads_code'];
            const googleAds = this.websiteJSONData['third_party_code']["google"]['google_tag_manager_id'];
            const cookie_fbp = this.cookie.get('_fbp');
            const cookie_fbc = this.cookie.get('_fbc');
            if (googleAds) {
              this.scriptsService.googleConversionDP(val.data.amount, val.data._id, val.data.selected_promotion, val.data.transaction_fee, val.data.from_login_id, this.userProfile.mobile_phone_number, val.data.paymentgateway_method, val.data.paymentgateway_transaction, val.data.paymentgateway_name, '');
            }
            for (let i of adsCodeMeta.facebook) {
              this.request.facebookPixel(i.id, i.token, 'Purchase', cookie_fbc, cookie_fbp, val.data.from_login_id, this.userProfile.mobile_phone_number, val.data.amount, val.data._id, val.data.paymentgateway_method, val.data.paymentgateway_transaction, val.data.paymentgateway_name, '').subscribe({
                next: (response) => {
                  // console.log(response);
                },
                error: (error) => { },
                complete: () => { }
              })
            }
            //------------- end ads conversion -----------//
          }
        },
        error: (e) => {
          console.log(e);
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: { message: e.error.messages[0].message, type: 'error' },
            duration: 3000,
            verticalPosition: 'top',
            panelClass: "notification-error"
          });
          this.disabledButton = false;
          this.inProgress = false;
        },
        complete: () => { }
      })
    } else {
      let messageError = 'Nominal Harus Kelipatan 1.000';
      if ((this.inputAmount * 1000) < 10000){
        messageError = "Minimal Jumlah Deposit Rp 10.000";
      }
      if ((this.inputAmount * 1000) > 10000000){
        messageError = "Maksimum Jumlah Deposit Rp 10.000.000";
      }
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: { message: messageError, type: 'error' },
        duration: 3000,
        verticalPosition: 'top',
        panelClass: "notification-error"
      });
    }
  }

  backToMainDeposit() {
    this.inputAmount = undefined;
    this.currentNominal = 0;
    this.showError = false;
    // this.showPromo = false;
    this.statusPage = 'form';
  }

  cancelAutoPayment() {
    this.disabledButtonSub = true;
    this.request.cancelTransactionAutoDetail(this.auth.sessionValue, this.autoData['_id']).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          // console.log(val);
          // this.checkActiveDepositAuto();
          this.backToMainDeposit();
          this.checkActiveDepositAuto();
          this.disabledButtonSub = false;
        }
      },
      error: (e) => {
        console.log(e);
        // this.snackBar.openFromComponent(SnackbarComponent, {
        //   data: { message: e.error.messages[0].message, type: 'error' },
        //   duration: 3000,
        //   verticalPosition: 'top',
        //   panelClass: "notification-error"
        // });
        this.backToMainDeposit();
        this.checkActiveDepositAuto();
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  checkActiveDepositAuto() {
    this.loader = true;
    if (this.intervalStatus) {
      clearInterval(this.intervalStatus)
    }
    this.request.checkActiveTransactionAuto(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          // console.log(val);
          if (val['data']) {
            this.autoData = val['data'];
            this.autoData['amount'] = parseInt(this.autoData['amount']);
            this.autoData['transaction_fee'] = parseInt(this.autoData['transaction_fee']);

            this.statusPage = 'confirm'
            this.checkIntervalStatusDeposit();
            this.countTime();
          } else {
            this.checkActiveDepositManual();
            // this.statusPage = 'form'
            // if (this.intervalStatus) {
            //   clearInterval(this.intervalStatus);
            // }
          }
          // this.loader = false;
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
      },
      complete: () => { }
    })
  }

  checkStatusDepositAuto() {
    this.disabledButtonSub = true;
    this.request.checkTransactionAuto(this.auth.sessionValue, this.autoData['_id']).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          this.disabledButtonSub = false
          if (val['data']['status'] === 2) {
            this.statusPage = 'success';
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
            this.countDown();
          }
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  checkStatusDepositManual() {
    this.disabledButtonSub = true;
    this.request.checkActiveTransactionManual(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          console.log(val);
          this.disabledButtonSub = false
          if (val['data']['status'] === 2) {
            this.statusPage = 'success';
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
            this.countDown();
          }
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
        this.disabledButtonSub = false
      },
      complete: () => { }
    })
  }

  checkActiveDepositManual() {
    this.loader = true;
    // if (this.intervalStatus) {
    //   clearInterval(this.intervalStatus)
    // }
    this.request.checkActiveTransactionManual(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          if (val['data']) {
            this.manualData = val['data'];
            this.noteDeposit.setValue(val['data']['transaction_note']);
            this.currentBankManual = val['data']['to_bank'];
          //   this.autoData = val['data'];
          //   this.autoData['amount'] = parseInt(this.autoData['amount']);
          //   this.autoData['transaction_fee'] = parseInt(this.autoData['transaction_fee']);

            this.statusPage = 'confirm-bank'
          //   this.checkIntervalStatusDeposit();
          //   this.countTime();
          } else {
            this.statusPage = 'form';
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
          }
          this.loader = false;
        }
      },
      error: (e) => {
        console.log(e);
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: { message: e.error.messages[0].message, type: 'error' },
          duration: 3000,
          verticalPosition: 'top',
          panelClass: "notification-error"
        });
      },
      complete: () => { }
    })
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  countDown() {
    if (isPlatformBrowser(this.platformId)) {

      var x = setInterval(() => {
        this.timeCountSecond = this.timeCountSecond - 1;

        if (this.timeCountSecond < 0) {
          this.timeCountSecond = 0;
          clearInterval(x);
          this.timeCountSecond = 5;
          this.router.navigate(['/transaction'])
        }
      }, 1000);
    }
  }

  checkIntervalStatusDeposit() {
    if (isPlatformBrowser(this.platformId)) {
      this.intervalStatus = setInterval(() => {
        this.request.checkTransactionAuto(this.auth.sessionValue, this.autoData['_id']).subscribe({
          next: (val) => {
            if (val['code'] === 200) {
              // console.log(val);
              if (val['data']['status'] === 2) {
                this.statusPage = 'success';
                if (this.intervalStatus) {
                  clearInterval(this.intervalStatus);
                }
                this.countDown();
              }
            }
          },
          error: (e) => {
            console.log(e);
            this.snackBar.openFromComponent(SnackbarComponent, {
              data: { message: e.error.messages[0].message, type: 'error' },
              duration: 3000,
              verticalPosition: 'top',
              panelClass: "notification-error"
            });
            this.disabledButtonSub = false
            if (this.intervalStatus) {
              clearInterval(this.intervalStatus);
            }
          },
          complete: () => { }
        })
      }, 15000);
    }
  }

  goToTransaction() {
    this.router.navigate(['/transaction']);
  }

  countTime() {
    dayjs.extend(utc);
    const time1 = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
    const time2 = dayjs(this.autoData.paymentgateway_expired_at, 'YYYY-MM-DD HH:mm:ss');
    const diffInSeconds = time2.diff(time1, 'second');
    this.minutes = Math.floor(diffInSeconds / 60);
    this.seconds = diffInSeconds % 60;
    this.startCountdown();
  }

  checkCountdown() {
    this.minutesShow = String(this.minutes).padStart(2, '0');
    this.secondsShow = String(this.seconds).padStart(2, '0');
  }

  startCountdown() {
    this.intervalId = setInterval(() => {
      dayjs.extend(utc);
      const time1 = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
      const time2 = dayjs(this.autoData.paymentgateway_expired_at, 'YYYY-MM-DD HH:mm:ss');
      const diffInSeconds = time2.diff(time1, 'second');
      this.minutes = Math.floor(diffInSeconds / 60);
      this.seconds = diffInSeconds % 60;

      if (this.seconds <= 0) {
        if (this.minutes <= 0) {
          this.stopCountdown();
        } else {
          this.minutes--;
          this.seconds = 59;
          this.checkCountdown();
        }
      } else {
        this.seconds--;
        this.checkCountdown();
      }
    }, 1000);
  }

  stopCountdown() {
    this.minutesShow = "00";
    this.secondsShow = "00";
    this.showError = true;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.intervalStatus) {
      clearInterval(this.intervalStatus);
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getBankData();
      this.subscriptionData();
      this.getDepositConfig();
      this.getPromoList();
      // this.getBankCompanyByGroup();
      this.userGroupData = this.auth.userGorupData;
      this.userProfile = this.auth.userProfileData;
      this.minDPAmount = this.data.transactionConfigJSONData['min_deposit_amount'];
      this.maxDPAmount = this.data.transactionConfigJSONData['max_deposit_amount'];
      this.inputUserBankName = this.auth.userProfileData['first_name'] + (this.auth.userProfileData['last_name'] ? ' ' + this.auth.userProfileData['last_name'] : '');
      this.transactionConfig = this.data.transactionConfigJSONData;
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
      this.stopCountdown();
    }
  }
}
