import {
  Component,
  ElementRef,
  Inject,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faUser,
  faEyeSlash,
  faEye,
  faPhone,
  faBank,
  faUserGroup,
  faCaretDown,
  faMagnifyingGlass,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FormsModule, NgForm, ReactiveFormsModule } from '@angular/forms';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from '@componentpath/parts/toast/toast.component';
import { ToastService } from '@componentpath/services/toast.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { DomSanitizer } from '@angular/platform-browser';
import { ScriptsService } from '@componentpath/services/scripts.service';

@Component({
  selector: 'app-register-input',
  standalone: true,
  templateUrl: './register-input.component.html',
  styleUrl: './register-input.component.scss',
  imports: [
    FontAwesomeModule,
    FormsModule,
    CommonModule,
    NgbToastModule,
    ReactiveFormsModule, 
    NgbDropdownModule,
    ToastComponent,
  ],
})
export class RegisterInputComponent {
  faUser: IconProp = faUser;
  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;
  faPhone: IconProp = faPhone;
  faBank: IconProp = faBank;
  faUserGroup: IconProp = faUserGroup;
  faCaretDown: IconProp = faCaretDown;
  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faCircleCheck: IconProp = faCircleCheck;

  show = true;
  username = '';
  password = '';
  confirmPassword = '';
  pin = '';
  confirmPin = '';
  mobilePhone = '';
  bank = '';
  account_number = '';
  account_name = '';
  referral = '';
  captcha = '';

  isReferralDisabled = false;
  isCaptchaMatch = false;

  isUsernameAvailable = true;
  isPasswordVisible = false;
  isConfirmPasswordVisible = false;
  isPinVisible = false;
  isConfirmPinVisible = false;
  disableRegister = false;
  imgCaptcha: any;
  options: any[] = [];
  searchedOptions: any[] = [];
  isDropdownVisible: boolean = false;
  selectedOption: string = '';
  dropdownOpened: boolean = false;
  showBankNotification: boolean = false;

  inputSearchCreate: string = '';
  searchedCreateOptions: Array<any> = [];
  currentSelectedCreateBank: Record<string, any> = {};

  websiteJSONData: any = this.data.websiteJSONData;

  @ViewChild('errorToastTpl') errorToastTpl!: TemplateRef<any>;
  
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private scriptsService: ScriptsService,
    private router: Router,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private cookie: SsrCookieService,
    private toastService: ToastService
  ) { }

  toggleDropdown(event: MouseEvent): void {
    this.isDropdownVisible = !this.isDropdownVisible;
    event.stopPropagation();
  }

  selectedCreateBank(e: any) {
    this.currentSelectedCreateBank = e;
  }

  onSearchcreateValue(e: Event) {
    const input = this.inputSearchCreate.toLowerCase();
    let typeLists: Array<any> = [];

    typeLists = this.options;

    this.searchedCreateOptions = typeLists.filter((option) =>
      (option['name'].toLowerCase().includes(input))
    );

    if (!input) {
      this.searchedCreateOptions = [...typeLists];
    }
  }

  handleOutsideClick(event: MouseEvent): void {
    if (this.elementRef.nativeElement.contains(event.target)) {
      if (this.isDropdownVisible) {
        this.isDropdownVisible = false;
        this.showBankNotification = !this.selectedOption;
      }
    }
  }

  getCaptcha(): void {
    this.request.getCaptcha().subscribe({
      next: (response) => {
        this.imgCaptcha = this.domSanitizer.bypassSecurityTrustHtml(response);
      },
      error: (error) => {
        console.log(error)
      },
    });
  }

  onSearchDropdownValue(event: Event): void {
    const input = (event.target as HTMLInputElement).value.toLowerCase();
    this.searchedOptions = this.options.filter((option) =>
      option.name.toLowerCase().includes(input)
    );
    if (!input) {
      this.searchedOptions = this.options;
    }

    this.isDropdownVisible = this.searchedOptions.length > 0;
  }

  onSelectDropdownValue(option: any): void {
    this.selectedOption = option;
    this.searchedOptions = [];
    this.isDropdownVisible = false;

    this.showBankNotification = false;
  }
  onDocumentClick(event: MouseEvent): void {
    const isClickInside = this.elementRef.nativeElement.contains(event.target);
    if (!isClickInside && this.isDropdownVisible) {
      this.isDropdownVisible = false;
    }
  }

  toUppercase(event: string, field: string): void {
    if (field === 'username') {
      this.username = event.toUpperCase();
    } else if (field === 'referral') {
      this.referral = event.toUpperCase();
    }
  }
  checkUsernameAvailability(): void {
    if (this.username.trim() === '') {
      return;
    }

    this.request.checkUsername(this.username).subscribe({
      next: (response) => {
        console.log(response.data.validate, 'response avail');
        this.isUsernameAvailable = true;
      },
      error: (error) => {
        console.error('Error checking username:', error);
        this.isUsernameAvailable = false;
      },
    });
  }
  toggleVisibility(field: string): void {
    switch (field) {
      case 'password':
        this.isPasswordVisible = !this.isPasswordVisible;
        break;
      case 'confirmPassword':
        this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
        break;
      case 'pin':
        this.isPinVisible = !this.isPinVisible;
        break;
      case 'confirmPin':
        this.isConfirmPinVisible = !this.isConfirmPinVisible;
        break;
      default:
        break;
    }
  }
  public checkPinMatch(): boolean {
    return this.pin === this.confirmPin;
  }

  public checkPasswordMatch(): boolean {
    return this.password === this.confirmPassword;
  }

  sendRegister(){
    if(!this.websiteJSONData.configuration.pin){
      this.pin = '111111';
    }

    this.request.registerUser(
        '',
        this.username,
        this.password,
        this.pin,
        this.referral,
        '',
        this.mobilePhone,
        'ID',
        this.currentSelectedCreateBank['_id'] as string,
        this.account_name,
        this.account_number
      )
      .subscribe({
        next: (response) => {
          if (response.code === 200) {
            response.data.hide_pin = true;
            // response.data.group = {
            //   "_id": "63d94aa59b650dff710cce53",
            //   "user": "634ff0bde83e948a230b9402",
            //   "name": "BRONZE",
            //   "keyword": "BRONZE",
            //   "rank": 1,
            //   "updated_at": "2024-05-17 08:00:29",
            //   "created_at": "2023-01-31 17:06:45",
            //   "auto_upgrade": true,
            //   "private": false,
            //   "badge_image": "",
            //   "card_image": "",
            //   "rule": [],
            //   "status": true
            // }
            this.auth.setLogin(response.data, {});
            // this.cookie.delete('ref');
            //ads conversion
            const adsCodeMeta = this.websiteJSONData['ads_code'];
            const googleAds = this.websiteJSONData['third_party_code']["google"]['google_tag_manager_id'];
            const cookie_fbp = this.cookie.get('_fbp');
            const cookie_fbc = this.cookie.get('_fbc');
            if (googleAds) {
              this.scriptsService.googleConversionRegister(this.username, response.data.profile.mobile_phone_number, this.currentSelectedCreateBank['name'], this.account_number, this.account_name, this.referral);
            }
            for (let i of adsCodeMeta.facebook) {
              this.request.facebookPixel(i.id, i.token, 'Register', cookie_fbc, cookie_fbp, this.username, response.data.profile.mobile_phone_number, '', '', this.currentSelectedCreateBank['name'], this.account_number, this.account_name, this.referral).subscribe({
                next: (response) => {
                  console.log(response);
                },
                error: (error) => { },
                complete: () => { }
              })
            }
            //------------- end ads conversion -----------//
          } else {
            console.error('Registration failed:', response.message);
            const message = response.message || 'Registration failed';
            this.toastService.show({
              template: this.errorToastTpl,
              classname: 'bg-danger text-light',
              context: { message },
              delay: 8000,
            });
          }
        },
        error: (error) => {
          const errorMessage =
            error.error.messages[0].message || 'Registration failed';
          this.toastService.show({
            template: this.errorToastTpl,
            classname: 'bg-danger text-light',
            context: { message: errorMessage },
            delay: 8000,
          });
          this.disableRegister = false;
        },
        complete: () => {
          this.router.navigate(['/']);
          console.log(this.auth.sessionValue);
        },
      });
  }

  public register(form: NgForm): void {
    this.disableRegister = true;
    if (isPlatformBrowser(this.platformId)) {
      if (form.invalid) {
        this.toastService.show({
          template: this.errorToastTpl,
          classname: 'bg-danger text-light',
          delay: 8000,
          context: { message: 'Please complete all required fields.' },
        });
        
        this.disableRegister = false;
        return;
      } else {
        //if captcha true
        if (this.websiteJSONData.configuration.captcha){
          this.request.checkCaptcha(this.captcha).subscribe({
            next: (response) => {
              if (response.code === 200) {
                this.sendRegister();
              } else {
                this.toastService.show({
                  template: this.errorToastTpl,
                  classname: 'bg-danger text-light',
                  delay: 8000,
                  context: { message: 'Captcha tidak cocok' },
                });
                this.disableRegister = false;
              }
            },
            error: (error) => {
              console.log(error)
            },
          });
        } else {
          //if captcha false
          this.sendRegister();
        }

      }

    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.websiteJSONData.configuration.captcha) {
        this.getCaptcha();
      }
    }
    let dataBank = this.data.bankRegisterJSONData['records'] as any[];
    dataBank.sort((a, b) => parseFloat(a['sort']) - parseFloat(b['sort']) || a["name"].localeCompare(b["name"]));
    this.options = dataBank.filter((option) =>
      ((option['type'] === 1 || option['type'] === 2) && option['code'] !== 'oth' && option['status'] && option['display_register'])
    );
    this.searchedCreateOptions = this.options;
    // this.currentSelectedCreateBank = this.options[0];
    this.activatedRoute.queryParams.subscribe((params) => {
      // if (params['ref']) {
      //   this.referral = params['ref'].toUpperCase();
      //   this.isReferralDisabled = true;
      // }
      if (params['ref'] != undefined && params['ref'] != '') {
        this.referral = params['ref'].toUpperCase();
        this.isReferralDisabled = true;
      } else {
        let dataRef = this.cookie.get('ref');
        // console.log(dataRef)
        if (dataRef) {
          this.referral = dataRef.toUpperCase();
          this.isReferralDisabled = true;
        }
        // let dataRef = this.cookies.get('v3_ref');
        // if (dataRef) {
        //   this.referralRegister.setValue(dataRef.toUpperCase());
        //   this.referralRegister.disable();
        // }
      }
    });
  }

  ngOnDestroy(): void {
    this.toastService.clear();
  }
}
